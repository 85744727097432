import { Args, StoryFn } from "@storybook/html";

export const createComponentStory = (Template: StoryFn, args: Args) => {
  const templateFunc: StoryFn<Args> = Template.bind({});
  templateFunc.args = args;

  return templateFunc;
};

export const fireDomReady = () => {
  const event = new CustomEvent("fireDomReady");
  document.dispatchEvent(event);
};

export const domReady = (cb: () => void) => {
  document.addEventListener("DOMContentLoaded", cb);
};

export const attachPrintButtonEvent = () => {
  const printBtn: HTMLButtonElement | null =
    document.querySelector(".print-button");

  if (printBtn) {
    printBtn.addEventListener("click", () => {
      window.print();
    });
  }
};

// Generic keyboard event handler, invoking "cb" if e.code matches "keyCode"
export const useKeyHandler = (
  keyCode: KeyboardEvent["code"],
  cb: () => void
): void => {
  const handleKeydown = (e: KeyboardEvent) => {
    if (e.code === keyCode) {
      cb();
    }
  };

  document.addEventListener("keydown", handleKeydown);
};

// Check if JS is disabled
export const isJsDisabled = () => document.body.classList.contains("no-js");

// Add/update query params in URL
export const setURLSearchParams = (key: string, value: string) => {
  const params = new URLSearchParams(window.location.search);
  params.set(key, value);
  window.history.pushState({}, "", `${location.pathname}?${params}`);
};

// Get query string params that match `keys` and construct into object: { key: value[] }
export const getQueryStringParamsAsKeyValuePair = (keys: string[]) => {
  const params = new URLSearchParams(window.location.search);

  return keys.reduce((acc, key) => {
    const paramsByKey = params.getAll(key);

    return {
      ...(paramsByKey.length > 0 && { [key]: params.getAll(key) }),
      ...acc,
    };
  }, {}) as Record<string, string[]>;
};

export const attachHistoryBackLinkEvent = () => {
  const historyBackLinks = document.querySelectorAll(".js-history-back-link");

  historyBackLinks?.forEach((link) =>
    link.addEventListener("click", (e) => {
      e.preventDefault();
      history.back();
    })
  );
};

// Check the screens size and return true if it is mobile
export const getDeviceInfo = () => {
  const mobileScreenSize = 768;
  const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  const isMobile = screenWidth <= mobileScreenSize; // You can adjust the threshold based on your needs

  return { isMobile };
};