const TableModule = () => {
  const rteTables = document.querySelectorAll(
    ".rte table"
  ) as NodeListOf<HTMLTableElement>;

  const checkOverflow = (table: HTMLTableElement) => {
    const parentRte = table.closest(".rte") as HTMLDivElement;

    if (table.scrollWidth > parentRte.offsetWidth) {
      wrapWithHorizontalScroller(table);
    }
  };

  const wrapWithHorizontalScroller = (table: HTMLTableElement) => {
    const horizontalScrollerMain = document.createElement("div");
    horizontalScrollerMain.classList.add(
      "horizontal-scroller",
      "horizontal-scroller--with-swipe-icon"
    );
    const horizontalScrollerInner = document.createElement("div");
    horizontalScrollerInner.classList.add("horizontal-scroller__scroller");

    // Add inner scroller element to main wrapper
    horizontalScrollerMain.appendChild(horizontalScrollerInner);
    // Replace current table element with horisontal scroller el
    table.replaceWith(horizontalScrollerMain);
    // Add table inside inner scroller element
    horizontalScrollerInner.appendChild(table);
  };

  const init = () => {
    rteTables.forEach((table) => {
      checkOverflow(table);
    });
  };

  return {
    init,
  };
};

export { TableModule };
