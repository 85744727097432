/*
  Common filter logic for handling change events on filter inputs and query parameters
  See stories/5-filter for more documentation on this module
*/

const LiveFilter = (
  // Query string key for filter values
  filterKey: string,
  // HTMLInput triggers (checkboxes or radios) for filter
  triggers: NodeListOf<HTMLInputElement> | HTMLInputElement[],
  // Btn for clearing all filter values under filterKey
  clearBtn?: HTMLButtonElement,
  // Callback to run on each filter update
  onUpdate?: () => void
) => {
  /*
   * @param e: Event (Change event on filter HTMLInput)
   */
  const updateFilter = (e: Event) => {
    const currentTarget = e.currentTarget as HTMLInputElement;
    const { value } = currentTarget;

    // Add or remove filter value
    currentTarget.checked ? addFilterValue(value) : deleteFilterValue(value);

    // Trigger optional callback
    onUpdate && onUpdate();
    // Trigger custom event that other functions can hook into
    const event = new CustomEvent("livefilterUpdated");
    document.dispatchEvent(event);
  };

  /*
   * @param value: string (Value from input that triggered change event)
   */
  const addFilterValue = (value: string) => {
    const params = new URLSearchParams(window.location.search);
    // If there's already a filter w/ this key in URL we append, except for 'sort'
    if (params.has(filterKey) && filterKey.indexOf("sort") === -1) {
      // Don't add a filter that already exists
      if (!params.getAll(filterKey).includes(value)) {
        params.append(filterKey, value);
      }
    } else {
      // Otherwise we set filterKey and value
      params.set(filterKey, value);
    }

    // Update URL
    window.history.pushState({}, "", `${location.pathname}?${params}`);
  };

  /*
   * @param value: string (Value from input that triggered change event)
   */
  const deleteFilterValue = (value: string) => {
    const params = new URLSearchParams(window.location.search);
    const newParams = params
      .getAll(filterKey)
      .filter((param) => param !== value);

    params.delete(filterKey);
    newParams.map((param, i) => {
      i === 0 ? params.set(filterKey, param) : params.append(filterKey, param);
    });

    // Update URL
    window.history.pushState({}, "", `${location.pathname}?${params}`);
  };

  const deleteAllFilterValues = () => {
    const params = new URLSearchParams(window.location.search);

    params.delete(filterKey);
    // Update URL
    window.history.pushState({}, "", `${location.pathname}?${params}`);
  };

  const clearFilter = () => {
    // Reset trigger states
    triggers.forEach((trigger) => {
      if (trigger.checked) {
        trigger.checked = false;
      }
    });

    // Delete filter query values
    deleteAllFilterValues();

    // Dispatch event to trigger onUpdate
    const changeEvent = new Event("change");
    triggers[0].dispatchEvent(changeEvent);
  };

  const init = () => {
    if (triggers?.length > 0) {
      triggers.forEach((trigger) =>
        trigger.addEventListener("change", updateFilter)
      );
    }
    if (clearBtn) {
      clearBtn.addEventListener("click", clearFilter);
    }
  };

  return {
    init,
  };
};

export { LiveFilter };
