import { AnimateHeight } from "@/scripts";

const AccordionModule = (accordion: HTMLDivElement) => {
  const toggle = (e: Event) => {
    const target = e.currentTarget as HTMLButtonElement;

    if (target.getAttribute("aria-expanded") === "false") {
      open(target);
    } else {
      close(target);
    }
  };

  const open = (target: HTMLButtonElement) => {
    const content = document.getElementById(
      target.getAttribute("aria-controls")!
    ) as HTMLDivElement;

    target.setAttribute("aria-expanded", "true");
    AnimateHeight(content).toAutoHeight();
    content.classList.add("accordion__content--visible");
  };

  const close = (target: HTMLButtonElement) => {
    const content = document.getElementById(
      target.getAttribute("aria-controls")!
    ) as HTMLDivElement;

    target.setAttribute("aria-expanded", "false");
    AnimateHeight(content).toZeroHeight();
    content.classList.remove("accordion__content--visible");
  };

  const init = () => {
    const triggers = accordion.querySelectorAll(
      ".accordion__trigger"
    ) as NodeListOf<HTMLButtonElement>;

    triggers.forEach((trigger) => trigger?.addEventListener("click", toggle));
  };

  return {
    init,
  };
};

const initiateAccordions = () => {
  document
    .querySelectorAll(".accordion")
    ?.forEach((accordion) =>
      AccordionModule(accordion as HTMLDivElement).init()
    );
};

export { AccordionModule, initiateAccordions };
