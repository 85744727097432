/**
 Script module used for filtering page listing results
 See stories/5-filter for more documentation on this module
 */

import axios from "axios";
import { LiveFilter } from "./_live-filter";
import { FilterCheckboxProps } from "@/components/FilterCheckbox";

import { getQueryStringParamsAsKeyValuePair } from "@/scripts";

interface PageListingFilterResult {
  name: string;
  key: string;
  filterValues: FilterCheckboxProps[];
}

interface PageListingResult {
  result: string;
  filters: PageListingFilterResult[];
  total: number;
  success: boolean;
}

const PageListingFilter = (filterEl: HTMLDivElement) => {
  // Checkboxes that trigger filter
  let triggers = Array.from(
    filterEl.querySelectorAll(
      ".filter-checkbox__checkbox"
    ) as NodeListOf<HTMLInputElement>
  );
  // Keys used in filter fetch request and as query param keys
  const filterKeys = filterEl
    .getAttribute("data-filter-keys")!
    .split(",")
    .map((key) => key.trim());
  // ID of search input element
  const searchInputId = filterEl.getAttribute("data-search-input-id") as string;

  // Listen for updates from LiveFilter
  const onUpdate = async () => {
    const { result, filters, total } = await getFilterUpdate();

    updatePageListingHTML(result);
    updateFilters(filters);
    updateSnackbarAndSerpHeader(total);
  };

  // Fetch result of filter update
  const getFilterUpdate = async (): Promise<PageListingResult> => {
    const endpoint = filterEl.getAttribute("data-endpoint")!;
    // Get filter params
    const activeParams = getQueryStringParamsAsKeyValuePair(filterKeys);
    // Get search query param if search input element exists
    const searchInput: HTMLInputElement | null = document.getElementById(
      searchInputId
    ) as HTMLInputElement;

    const r = await axios.get(endpoint, {
      params: {
        ...activeParams,
        ...(searchInput && { [searchInput.name]: searchInput.value }),
      },
    });
    const { data } = r;

    return data;
  };

  // Replace page listing and pagination markup w/ updated HTML
  const updatePageListingHTML = (updatedListingHTML: string) => {
    const filterListingTargetId = filterEl.getAttribute(
      "data-page-listing-id"
    ) as string;
    const pageListingContainer = document.getElementById(
      filterListingTargetId
    ) as HTMLDivElement;

    pageListingContainer.innerHTML = updatedListingHTML;
  };

  // Update filters checkboxes w/ new state and filter count
  const updateFilters = (filters: PageListingFilterResult[]) => {
    // Update filter count
    filters.map((filter) => {
      filter.filterValues.map((filterValues) => {
        const filterCheckboxEl = document.getElementById(
          filterValues.id!
        ) as HTMLInputElement;

        if (filterCheckboxEl) {
          filterCheckboxEl.parentNode!.querySelector(
            ".filter-checkbox__count"
          )!.innerHTML = `(${String(filterValues.filterCount)})`;
        }
      });
    });

    // Update filter state
    triggers.map((trigger) => {
      filters.map((filter) => {
        filter.filterValues.map((filterValue) => {
          if (filterValue.id === trigger.id) {
            if (filterValue.disabled) {
              trigger.disabled = true;
            } else {
              trigger.disabled = false;
            }
          }
        });
      });
    });
  };

  // Update content in snackbar, used for announcing changes in filter to the user
  const updateSnackbarAndSerpHeader = (totalHits: number) => {
    const snackbar = document.getElementById("page-listing-filter-snackbar");

    // Element for result count in SERP header
    const serpHeaderResultCount = document.querySelector(
      ".serp-header__result-count"
    ) as HTMLSpanElement;

    if (snackbar) {
      const message = snackbar.getAttribute("data-message") as string;
      snackbar.innerText = message.replace(/%s/g, String(totalHits));
      snackbar.classList.add("notification--show");

      setTimeout(() => {
        snackbar.classList.remove("notification--show");
      }, 3000);
    }

    if (serpHeaderResultCount) {
      serpHeaderResultCount.innerText = String(totalHits);
    }
  };

  const init = () => {
    if (triggers?.length > 0 && filterKeys.length > 0) {
      // If sort controls exist on page add them to LiveFilter
      const sortControls = Array.from(
        document.querySelectorAll(
          ".sort-controls__control-input"
        ) as NodeListOf<HTMLInputElement>
      );
      triggers =
        sortControls.length > 0 ? [...triggers, ...sortControls] : triggers;

      // Initiate a LiveFilter instance for each filterKey
      filterKeys.map((filterKey) => {
        const filterKeyTriggers = triggers.filter(
          (trigger) => trigger.getAttribute("name") === filterKey
        );
        const filterClearBtn = document.getElementById(
          `filter-clear-btn-${filterKey}`
        ) as HTMLButtonElement;

        LiveFilter(
          filterKey,
          filterKeyTriggers,
          filterClearBtn,
          onUpdate
        ).init();
      });
    }
  };

  return {
    init,
  };
};

export { PageListingFilter };
