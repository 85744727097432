import { getCookieConsentByCategory } from "./_cookieBot";
let extellioScriptLoaded = false;

const getScript = (scriptSrc: string, cb: () => void) => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = scriptSrc;
    script.onload = cb;
    document.body.appendChild(script);
    extellioScriptLoaded = true;
};

export const initExtellioByConsent = () => {
    const scriptSrc = window.extellioConf?.scriptSrc;
    
    if (!scriptSrc) return;

    let userHasConsentedToStatisticsCookies =
        getCookieConsentByCategory("statistics");

    if (userHasConsentedToStatisticsCookies && extellioScriptLoaded == false) {
        getScript(scriptSrc, () => console.log("Extellio script loaded"));
    }

    // Listen for cookie consent
    // If statistics cookies were accepted initate Extellio
    window.addEventListener("CookiebotOnAccept", () => {
        userHasConsentedToStatisticsCookies =
            getCookieConsentByCategory("statistics");

        if (userHasConsentedToStatisticsCookies && extellioScriptLoaded == false) {
            getScript(scriptSrc, () => {
                console.log("Extellio script loaded")
                if (window?.extellio_actions) {
                    window.extellio_actions.push(['rememberCookieConsentGiven']);
                    console.log("Extellio consent given");
                }
            });
        }
    });  

    // If statistics cookies were declined tell extellio to remove cookie and stop extellio
    window.addEventListener("CookiebotOnDecline", () => {
        userHasConsentedToStatisticsCookies =
            getCookieConsentByCategory("statistics");
        if (userHasConsentedToStatisticsCookies == false) {
            if (window?.extellio_actions) {
                window?.extellio_actions.push(['forgetCookieConsentGiven']);
                console.log("Extellio consent removed");
            }
            if (window?.extellio) {
                window.extellio.stop();
            }
        }
    });
};
