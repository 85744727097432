/*
  Script used to trap focus inside a container
*/

import getFocusableElements from "./_getFocusableElements";

const focusTrap = (containerElement: HTMLElement, setFocusOnFirst = true) => {
  const focusableElements = getFocusableElements(containerElement);
  const firstFocusableEl = focusableElements[0];
  const lastFocusableEl = focusableElements[focusableElements.length - 1];

  const self = {
    activate() {
      if (setFocusOnFirst && firstFocusableEl) {
        firstFocusableEl.focus();
      }
      containerElement.addEventListener("keydown", self.onKeyDown);
    },
    deactivate() {
      containerElement.removeEventListener("keydown", self.onKeyDown);
      containerElement.classList.remove("js-focus-visible");
    },
    onKeyDown(e: KeyboardEvent) {
      const isTabPressed = e.key === "Tab";

      if (!isTabPressed && !e.shiftKey) {
        containerElement.classList.remove("js-focus-visible");
        return;
      } else {
        containerElement.classList.add("js-focus-visible");
      }

      if (!isTabPressed) return;

      if (e.shiftKey) {
        /* shift + tab */ if (document.activeElement === firstFocusableEl) {
          lastFocusableEl.focus();
          e.preventDefault();
        }
      } /* tab */ else {
        if (document.activeElement === lastFocusableEl) {
          firstFocusableEl.focus();
          e.preventDefault();
        }
      }
    },
  };

  return self;
};

export { focusTrap };
