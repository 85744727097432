// -----------------------------------------------------------------------------
// This file is for loading any component scripts.
// Use name rule *.Module.ts in component folder.
// Use Revealing Module Pattern to structure your script: http://jargon.js.org/_glossary/REVEALING_MODULE_PATTERN.md
// -----------------------------------------------------------------------------

import { domReady } from "@/scripts";
import { NavbarModule } from "@/components/Navbar/Navbar.Module";
import { MenuModule } from "@/components/Menu/Menu.Module";
import { initiateMenuToggles } from "@/components/MenuToggle/MenuToggle.Module";
import { ShortcutsModule } from "@/components/Shortcuts/Shortcuts.Module";
import { ScrollToTopModule } from "@/components/ScrollToTop/ScrollToTop.Module";
import { initiateHorizontalScrollers } from "@/components/HorizontalScroller/HorizontalScroller.Module";
import { TableModule } from "@/components/Table/Table.Module";
import { initiateAlphabetFilter } from "@/components/AlphabetFilter/AlphabetFilter.Module";
import { initiateAccordions } from "@/components/Accordion/Accordion.Module";
import { initiateDiseaseItem } from "@/components/DiseaseItem/DiseaseItem.Module";
import { ShowMoreModule } from "./ShowMore/ShowMore.Module";
import { initiateTabs } from "./Tabs/Tabs.Module";

domReady(() => {
  NavbarModule().init();
  MenuModule().init();
  ShortcutsModule().init();
  ScrollToTopModule().init();
  TableModule().init();

  // Accordions
  initiateAccordions();

  // Tabs
  initiateTabs();

  // Alphabet filter
  initiateAlphabetFilter();

  // Disease items
  initiateDiseaseItem();

  // Add horizontal scroll logic to all elements with .horizontal-scroller
  initiateHorizontalScrollers(document);

  // Add click listeners on all main menu toggles
  initiateMenuToggles();

  // Show more containers
  document
    .querySelectorAll(".show-more")
    .forEach((el) => ShowMoreModule(el as HTMLDivElement).init());
});
