const SortTable = (table: HTMLTableElement) => {
  const makeSortable = (hCell: HTMLTableCellElement) => {
    const hCellContent = hCell.textContent!;
    const btn = document.createElement("button");
    btn.classList.add("table-sortable__btn");
    btn.innerText = hCellContent;
    hCell.innerHTML = "";
    hCell.appendChild(btn);

    btn.addEventListener("click", () => sort(hCell));
  };

  const sort = (hCell: HTMLTableCellElement) => {
    const descendingThClass = "dir-desc";
    const ascendingThClass = "dir-asc";
    const nodes = hCell.closest("tr")!.cells;
    let columnIndex = 0; // Index of column to be sorted

    let dir: "dir-desc" | "dir-asc" = descendingThClass;

    // Set sort direction classes (or reset if dir === '')
    const setSortDirClasses = (element: HTMLElement, dir: string) => {
      element.classList.remove(descendingThClass);
      element.classList.remove(ascendingThClass);
      element.removeAttribute("aria-sort");
      if (dir) {
        element.classList.add(dir);

        if (dir === "dir-desc") {
          element.setAttribute("aria-sort", "descending");
        } else {
          element.setAttribute("aria-sort", "ascending");
        }
      }
    };

    // Change sorting direction if previously sorted
    if (hCell.classList.contains(descendingThClass)) {
      dir = ascendingThClass;
    }
    setSortDirClasses(hCell, dir);

    for (let i = 0; i < nodes.length; i++) {
      if (nodes[i] === hCell) {
        columnIndex = i;
      } else {
        setSortDirClasses(nodes[i], "");
      }
    }

    // Get text value of table cell
    const getValue = (element: HTMLTableCellElement) => {
      return element.textContent;
    };

    // Defines the sort order. The return value is a number whose positivity indicates the relative order of the two elements.
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/sort
    const compare = (
      a: HTMLTableRowElement,
      b: HTMLTableRowElement,
      index: number
    ) => {
      const reverse = dir === ascendingThClass;
      const x = getValue((reverse ? a : b).cells[index])!;
      const y = getValue((reverse ? b : a).cells[index])!;
      const value = parseFloat(x) - parseFloat(y);
      const bool = isNaN(value) ? x.localeCompare(y) : value;
      return bool;
    };

    const orgTbody: HTMLTableSectionElement = table.tBodies[0];

    // Rows to array so they can be sorted
    const rows: HTMLTableRowElement[] = Array.from(orgTbody.rows);

    rows.sort((a: HTMLTableRowElement, b: HTMLTableRowElement) =>
      compare(a, b, columnIndex)
    );

    const cloneTBody = orgTbody.cloneNode() as HTMLTableSectionElement;
    cloneTBody.append(...rows);
    table.replaceChild(cloneTBody, orgTbody);
  };

  const init = () => {
    const headingCells = table.querySelectorAll(
      "thead th"
    ) as NodeListOf<HTMLTableCellElement>;

    if (headingCells?.length > 0) {
      headingCells.forEach((hCell) => makeSortable(hCell));
    }
  };

  return {
    init,
  };
};

export { SortTable };
