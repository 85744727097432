/*
  Script module with methods for animating height of containers from zero to auto and vice versa.
*/

interface Options {
  duration?: number; // Transition duration in ms
  timing?: string; // Transition timing
}

enum AnimationState {
  None,
  Auto,
  Zero,
}

const AnimateHeight = (el: HTMLElement) => {
  const defaultOptions = {
    duration: 250,
    timing: "cubic-bezier(.91, .06, .08, .93)",
  };
  let orgTransition = "";
  let state: AnimationState = AnimationState.None;

  const toAutoHeight = ({ duration, timing }: Options = defaultOptions) => {
    const style = getComputedStyle(el);

    duration = duration || defaultOptions.duration;

    orgTransition = style.transition;

    const transition = [
      orgTransition,
      `height ${duration}ms ${timing || defaultOptions.timing}`.trim(),
    ]
      .filter((t) => t)
      .join(", ");

    state = AnimationState.Auto;
    el.style.transition = transition;
    el.style.height = style.height;

    const scrollHeight = `${el.scrollHeight}px`;

    requestAnimationFrame(() => (el.style.height = scrollHeight));

    const start = performance.now();

    requestAnimationFrame(function awaitCompletion() {
      const height = getComputedStyle(el).height;

      if (performance.now() - start < duration! && height !== scrollHeight) {
        requestAnimationFrame(awaitCompletion);
        return;
      }
    });
  };

  const toZeroHeight = ({ duration, timing }: Options = defaultOptions) => {
    const style = getComputedStyle(el);

    orgTransition = style.transition;
    const transition = [
      orgTransition,
      `height ${duration || defaultOptions.duration}ms ${
        timing || defaultOptions.timing
      }`.trim(),
    ]
      .filter((t) => t)
      .join(", ");

    state = AnimationState.Zero;
    el.style.transition = transition;
    el.style.height = style.height;

    requestAnimationFrame(() => (el.style.height = "0"));
  };

  const onTransitionEnd = () => {
    if (state === AnimationState.Auto) {
      el.style.height = "auto";
    }

    if (state === AnimationState.Zero) {
      el.style.height = "0";
    }

    el.style.transition = orgTransition;
    orgTransition = "";
    state = AnimationState.None;
  };

  el.addEventListener("transitionend", onTransitionEnd);

  return {
    toAutoHeight,
    toZeroHeight,
  };
};

export { AnimateHeight };
