import { AnimateHeight } from "@/scripts";

const ShowMoreModule = (showMoreContainer: HTMLDivElement) => {
  const toggle = showMoreContainer.querySelector(
    ".show-more__toggle"
  ) as HTMLButtonElement;
  const contentContainer = showMoreContainer.querySelector(
    ".show-more__content"
  ) as HTMLDivElement;

  const showMore = () => {
    AnimateHeight(contentContainer).toAutoHeight();
    showMoreContainer.classList.add("show-more--expanded");

    toggle.setAttribute("aria-expanded", "true");
    toggle.removeEventListener("click", showMore);
  };

  const expandOnTab = (e: KeyboardEvent) => {
    const { key } = e;

    if (key === "Tab") {
      const activeElement = document.activeElement as HTMLElement;
      const contentContainerHeight = contentContainer.clientHeight;

      if (
        activeElement.offsetTop + activeElement.offsetHeight + 20 >=
        contentContainerHeight
      ) {
        showMore();
      }
    }
  };

  const init = () => {
    toggle.addEventListener("click", showMore);
    contentContainer.addEventListener("keyup", expandOnTab);

    setTimeout(() => {
      // If content height is lower than the container we disable the show more logic
      const contentHeight = contentContainer.firstElementChild!.clientHeight;
      const contentContainerHeight = contentContainer.clientHeight;
      if (contentContainerHeight > contentHeight) {
        showMore();
      }
    }, 100);
  };

  return {
    init,
  };
};

export { ShowMoreModule };
