import { setURLSearchParams } from "@/scripts";

import { initiateHorizontalScrollers } from "../HorizontalScroller/HorizontalScroller.Module";

const TabsModule = (tabsDiv: HTMLDivElement) => {
  const tabs = Array.from(
    tabsDiv.querySelectorAll(
      "button[role=tab]"
    ) as NodeListOf<HTMLButtonElement>
  );
  const firstTab = tabs[0];
  const lastTab = tabs[tabs.length - 1];

  const toggle = (tab: HTMLButtonElement) => {
    const tabId = tab.getAttribute("id");

    if (tab.getAttribute("aria-selected") === "false") {
      open(tab);
    }

    tabs.forEach((tab) => tab.id !== tabId && close(tab));
  };

  const setPreviousTab = (tab: HTMLButtonElement) => {
    const indexOfTab = tabs.indexOf(tab);

    if (tab === firstTab) {
      toggle(lastTab);
    } else {
      toggle(tabs[indexOfTab - 1]);
    }
  };

  const setNextTab = (tab: HTMLButtonElement) => {
    const indexOfTab = tabs.indexOf(tab);

    if (tab === lastTab) {
      toggle(firstTab);
    } else {
      toggle(tabs[indexOfTab + 1]);
    }
  };

  const onKeydown = (e: KeyboardEvent) => {
    const { key } = e;
    const target = e.currentTarget as HTMLButtonElement;
    let toggled = false;

    switch (key) {
      case "ArrowLeft":
        setPreviousTab(target);
        toggled = true;
        break;

      case "ArrowRight":
        setNextTab(target);
        toggled = true;
        break;

      case "Home":
        toggle(firstTab);
        toggled = true;
        break;

      case "End":
        toggle(lastTab);
        toggled = true;
        break;

      default:
        break;
    }

    if (toggled) {
      e.stopPropagation();
      e.preventDefault();
    }
  };

  const open = (tab: HTMLButtonElement) => {
    const panel = document.getElementById(
      tab.getAttribute("aria-controls")!
    ) as HTMLDivElement;

    tab.setAttribute("aria-selected", "true");
    tab.setAttribute("tabindex", "0");
    tab.focus();

    if (panel) {
      panel.classList.remove("tabs__panel--hidden");
    }

    // BE can set `tabs--disable-qs` to disable the updating of query strings
    if (!tabsDiv.classList.contains("tabs--disable-qs")) {
      setURLSearchParams("tab", tab.getAttribute("id")!);
    }

    // If there's a horizontal scroller element inside a tab we need to reinitalize it
    initiateHorizontalScrollers(panel);
  };

  const close = (tab: HTMLButtonElement) => {
    const panel = document.getElementById(
      tab.getAttribute("aria-controls")!
    ) as HTMLDivElement;

    tab.setAttribute("aria-selected", "false");
    tab.setAttribute("tabindex", "-1");

    if (panel) {
      panel.classList.add("tabs__panel--hidden");
    }
  };

  const init = () => {
    tabs.forEach((tab) => {
      tab?.addEventListener("click", (e: MouseEvent) => {
        const target = e.currentTarget as HTMLButtonElement;
        toggle(target);
      });
      tab?.addEventListener("keydown", onKeydown);
    });
  };

  return {
    init,
  };
};

const initiateTabs = () => {
  document
    .querySelectorAll(".tabs")
    ?.forEach((tabsDiv) => TabsModule(tabsDiv as HTMLDivElement).init());
};

export { TabsModule, initiateTabs };
