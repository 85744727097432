// Styles & Icons
import "normalize.css";
import "@/icons/sprite.symbol.svg";
import "@/styles/main.scss";

// Component scripts
import "@/components/index";

// Helper scripts
import {
  domReady,
  attachPrintButtonEvent,
  attachHistoryBackLinkEvent,
  PageListingFilter,
  StatisticsContentSwitcher,
  SortTable,
  ShowMore,
  ReadSpeaker,
  initExtellioByConsent,
} from "./scripts";

// Initialize scripts after DOMContentLoaded event
domReady(() => {
  // Enable check for Javascript
  document.body.classList.remove("no-js");

  // Initiate Extellio based on cookie consent
  initExtellioByConsent();

  // Listener for print button action
  attachPrintButtonEvent();

  // Listener for custom history back links
  attachHistoryBackLinkEvent();

  // Initiate ReadSpeaker
  ReadSpeaker().init();

  // Initiate page listing filter
  const pageListingFilter = document.querySelector(
    ".page-listing-filter",
  ) as HTMLDivElement;

  if (pageListingFilter) {
    PageListingFilter(pageListingFilter).init();
  }

  // Initiate statistics content switcher on Statistics pages
  document.querySelectorAll(".statistics-content-switcher").forEach((el) => {
    StatisticsContentSwitcher(el as HTMLDivElement).init();
  });

  // Add sorting feature to tables with class "table-sortable"
  document.querySelectorAll(".table-sortable").forEach((table) => {
    SortTable(table as HTMLTableElement).init();
  });

  // Initiate show more helper
  document.querySelectorAll(".show-more-trigger").forEach((trigger) => {
    ShowMore(trigger as HTMLButtonElement).init();
  });
});
