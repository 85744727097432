import axios from "axios";
import { AnimateHeight } from "@/scripts";

const MenuModule = () => {
  const menuToggleSelector = ".menu__item-toggle";
  const menuItemContentSelector = ".menu__item-content";
  const submenuWrapperClass = "menu__list-wrapper";
  const menuToggles: NodeListOf<Element> =
    document.querySelectorAll(menuToggleSelector);

  /*
   * Determine if submenu should open or close
   */
  const toggleSubmenu = (e: Event) => {
    e.preventDefault();
    const toggle = e.target as HTMLButtonElement;

    if (toggle && toggle.getAttribute("aria-expanded") === "false") {
      open(toggle);
    } else {
      close(toggle);
    }
  };

  /*
   * Fetch data for submenu and set to HTML element
   */
  const getSubmenuAsElement = async (
    endpoint: string,
    submenuId: string
  ): Promise<HTMLDivElement> => {
    const r = await axios.get(endpoint);
    const { data } = r;

    const submenuEl = document.createElement("div");
    submenuEl.classList.add(submenuWrapperClass);
    submenuEl.setAttribute("id", submenuId);
    submenuEl.innerHTML = data;

    return submenuEl;
  };

  /*
   * Add toggle event to submenu toggle buttons
   */
  const addClickHandler = (toggle: Element) => {
    toggle.addEventListener("click", toggleSubmenu);
  };

  /*
   * Open submenu and attach click handlers to new submenu toggles
   */
  const open = async (toggle: HTMLButtonElement) => {
    // Endpoint and submenu ID for fetching menu
    const endpoint = toggle.getAttribute("data-submenu-endpoint");
    const submenuId = toggle.getAttribute("data-aria-controls")!;
    // Main wrapper element for menu item
    const menuItem = toggle.closest(".menu__item");
    // Submenu element
    const submenu = document.getElementById(submenuId);

    // If we've already fetched submenu - just expand
    if (submenu) {
      AnimateHeight(submenu).toAutoHeight();
      submenu.classList.add(`${submenuWrapperClass}--visible`);
    } else {
      // Else we fetch submenu from "endpoint" and append to markup
      if (endpoint) {
        const submenuEl = await getSubmenuAsElement(endpoint, submenuId);
        const parent = toggle.closest(menuItemContentSelector);

        if (parent) {
          // Append submenu to DOM
          parent.after(submenuEl);

          AnimateHeight(submenuEl).toAutoHeight();
          submenuEl.classList.add(`${submenuWrapperClass}--visible`);

          // Add click handler to new toggles inside submenu
          const submenuToggles = submenuEl.querySelectorAll(menuToggleSelector);
          submenuToggles.forEach((submenuToggle) =>
            addClickHandler(submenuToggle)
          );
        }
      }
    }

    toggle.setAttribute("aria-expanded", "true");
    menuItem?.classList.add("menu__item--expanded");
    updateDescriptiveExpandedLabel(toggle, true);
  };

  const close = (toggle: HTMLButtonElement) => {
    const submenuId = toggle.getAttribute("data-aria-controls")!;
    const submenu = document.getElementById(submenuId) as HTMLDivElement;
    const menuItem = toggle.closest(".menu__item") as HTMLLIElement;

    toggle.setAttribute("aria-expanded", "false");
    AnimateHeight(submenu).toZeroHeight();
    submenu.classList.remove(`${submenuWrapperClass}--visible`);
    menuItem.classList.remove("menu__item--expanded");

    const submenuChildren = submenu.querySelectorAll(
      `.${submenuWrapperClass}`
    ) as NodeListOf<HTMLElement>;

    submenuChildren.forEach((submenuChild) => {
      submenu
        .querySelectorAll(menuToggleSelector)
        .forEach((menuToggleChild) => {
          const menuItem = menuToggleChild.closest(
            ".menu__item"
          ) as HTMLLIElement;

          menuItem.classList.remove("menu__item--expanded");
          menuToggleChild.setAttribute("aria-expanded", "false");
        });
      submenuChild.classList.remove(`${submenuWrapperClass}--visible`);
      AnimateHeight(submenuChild).toZeroHeight({ duration: 0 });
    });

    updateDescriptiveExpandedLabel(toggle, false);
  };

  /*
   * Change descriptive expanded label for screen readers
   */
  const updateDescriptiveExpandedLabel = (
    toggle: HTMLButtonElement,
    expanded: boolean
  ) => {
    const expandedLabelEl = toggle.querySelector(
      ".menu__item-toggle-expanded-label"
    ) as HTMLSpanElement;
    expandedLabelEl.innerText = toggle.getAttribute(
      `data-expanded-${expanded}-label`
    ) as string;
  };

  const init = () => {
    menuToggles.forEach((menuToggle) => {
      addClickHandler(menuToggle);
    });
  };

  return {
    init,
  };
};

export { MenuModule };
