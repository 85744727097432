const ShortcutsModule = () => {
  const shortcutsBlocks = document.querySelectorAll(".shortcuts");

  const reveal = (
    showAllBtn: HTMLButtonElement,
    shortcutsBlock: HTMLElement
  ) => {
    shortcutsBlock.className = "shortcuts"; // Removes limit modifier class
    showAllBtn.remove();
  };

  const init = () => {
    if (shortcutsBlocks.length > 0) {
      for (let i = 0; i < shortcutsBlocks.length; i++) {
        const shortcutsBlock = shortcutsBlocks[i] as HTMLElement;
        const showAllBtn = shortcutsBlocks[i].querySelector(
          ".shortcuts__show-all-btn"
        ) as HTMLButtonElement;

        showAllBtn?.addEventListener("click", () =>
          reveal(showAllBtn, shortcutsBlock)
        );
      }
    }
  };

  return {
    init,
  };
};

export { ShortcutsModule };
