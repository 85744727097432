import debounce from "lodash.debounce";

const NavbarModule = () => {
  const navbarEl: HTMLElement | null = document.querySelector(".navbar");
  const stickyNavbarRoot: HTMLElement | null = document.querySelector(
    "[data-sticky-navbar-root]"
  );
  const mediaQuery = window.matchMedia("screen and (max-width: 968px)");

  let previousScrollPos = 0;

  const scrollHandler = debounce(() => {
    if (stickyNavbarRoot) {
      const scrollY =
        document.documentElement.scrollTop || document.body.scrollTop || 0;
      const stickNavbarRootRectTop =
        stickyNavbarRoot.getBoundingClientRect().top;

      // Set class to allow scroll reveal animation
      if (scrollY > stickNavbarRootRectTop) {
        navbarEl?.classList.add("navbar--with-scroll-animation");
      } else {
        navbarEl?.classList.remove("navbar--with-scroll-animation");
      }

      // Show navbar if scroll direction is UP and scrollY is below stickNavbarRootRect
      if (scrollY < previousScrollPos && scrollY > stickNavbarRootRectTop) {
        show();
      } else if (
        scrollY > previousScrollPos + 20 ||
        scrollY < stickNavbarRootRectTop
      ) {
        hide();
      }
      previousScrollPos = scrollY;
    }
  }, 10);

  const hide = () => {
    if (navbarEl?.classList.contains("navbar--reveal")) {
      navbarEl.classList.remove("navbar--reveal");
    }
  };

  const show = () => {
    if (!navbarEl?.classList.contains("navbar--reveal")) {
      navbarEl?.classList.add("navbar--reveal");
    }
  };

  const init = () => {
    if (navbarEl) {
      if (mediaQuery.matches) {
        window.addEventListener("scroll", scrollHandler);
      }
    }
  };

  return {
    init,
  };
};

export { NavbarModule };
