import throttle from "lodash.throttle";

const HorizontalScrollerModule = (el: HTMLElement) => {
  const scrollerEl = el.querySelector(
    ".horizontal-scroller__scroller"
  ) as HTMLDivElement;
  const contentContainer = scrollerEl?.children[0] as HTMLElement;

  // Default values
  let scrollPos = 0;
  let contentWidth = 0;
  let settings = {
    scrollPosStart: true,
    scrollPosEnd: false,
    hasOverflowX: false,
    withSwipeIcon: el.getAttribute("data-with-swipe-icon") === "true",
  };

  const checkScrollPosition = () => {
    (scrollPos = Math.ceil(
      scrollerEl.scrollLeft + scrollerEl.getBoundingClientRect().width
    )),
      (contentWidth = contentContainer.scrollWidth);

    settings = {
      ...settings,
      scrollPosStart: scrollerEl.scrollLeft === 0,
      scrollPosEnd: scrollPos >= contentWidth,
      hasOverflowX: scrollerEl.getBoundingClientRect().width < contentWidth,
    };
  };

  const checkOverflow = () => {
    settings.hasOverflowX = el.offsetWidth < contentContainer?.scrollWidth;
    checkScrollPosition();

    if (settings.hasOverflowX) {
      toggleOverflowIndicatorsVisibility();
    }

    if (settings.withSwipeIcon) {
      toggleSwipeIconVisibility(settings.hasOverflowX);
    }
  };

  const toggleSwipeIconVisibility = (visible: boolean) => {
    el.classList.toggle("horizontal-scroller--with-swipe-icon", visible);
  };

  const toggleOverflowIndicatorsVisibility = () => {
    el.classList.toggle(
      "horizontal-scroller--overflow-right",
      !settings.scrollPosEnd && settings.hasOverflowX
    );
    el.classList.toggle(
      "horizontal-scroller--overflow-left",
      !settings.scrollPosStart
    );
  };

  const handleScroll = () => {
    checkScrollPosition();
    toggleSwipeIconVisibility(false);

    toggleOverflowIndicatorsVisibility();
  };

  const handleResize = () => {
    checkOverflow();
    checkScrollPosition();
    toggleOverflowIndicatorsVisibility();
  };

  const init = () => {
    checkOverflow();

    window.addEventListener("resize", throttle(handleResize, 100));
    scrollerEl.addEventListener("scroll", throttle(handleScroll, 100));

    // Used to determine placement of swipe icon
    if (el.clientHeight > 1400) {
      el.classList.add("horizontal-scroller--tall");
    }
  };

  return {
    init,
  };
};

const initiateHorizontalScrollers = (container: HTMLElement | Document) => {
  const horizontalScrollerEls = container.querySelectorAll(
    ".horizontal-scroller"
  );

  horizontalScrollerEls?.forEach((el) =>
    HorizontalScrollerModule(el as HTMLElement).init()
  );
};

export { HorizontalScrollerModule, initiateHorizontalScrollers };
