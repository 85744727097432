import { LiveFilter } from "@/scripts/2-functions/_live-filter";

const AlphabetFilterModule = (filter: HTMLFieldSetElement) => {
  // Filter checkboxes
  const triggers = filter.querySelectorAll(
    ".alphabet-filter__checkbox-input"
  ) as NodeListOf<HTMLInputElement>;
  // Clear button
  const clearBtn = document.querySelector(
    ".alphabet-filter__clear-btn"
  ) as HTMLButtonElement;

  // Filter key for URL query string
  const filterKey = "filter-char";

  // Live region variables for announcing updates to screen readers
  const liveRegion = document.getElementById(
    "alphabet-filter-live-region"
  ) as HTMLDivElement;
  const liveRegionTextAll = liveRegion.getAttribute(
    "data-live-region-text-all"
  );
  const liveRegionTextFiltered = liveRegion.getAttribute(
    "data-live-region-text-filtered"
  );

  // Container for items to be filtered
  const filterTargetId = filter.getAttribute("data-alphabet-filter-target-id")!;
  const filterTarget = document.getElementById(filterTargetId);

  const onUpdate = () => {
    // Update text in live region
    updateLiveRegion();

    toggleFilterItemsVisibilty();
  };

  const updateLiveRegion = () => {
    const params = new URLSearchParams(window.location.search);

    const activeFilters = params.getAll(filterKey);

    if (activeFilters.length > 0) {
      liveRegion.innerText = `${liveRegionTextFiltered}: ${params
        .getAll(filterKey)
        .toString()}`;
    } else {
      liveRegion.innerText = liveRegionTextAll || "";
    }
  };

  const toggleFilterItemsVisibilty = () => {
    if (
      filterTarget?.children !== undefined &&
      filterTarget.children.length > 0
    ) {
      const params = new URLSearchParams(window.location.search);
      const { children } = filterTarget;

      for (let i = 0; i < children.length; i++) {
        const el = children[i] as HTMLUListElement;
        const activeParams = params.getAll(filterKey);

        // ID from element (eg "alphabet-filter-target-A")
        const elId = el.getAttribute("id")!;

        // Element ID transformed to match param ID (eg "A")
        const elParamId = elId.substring(
          filterTargetId.length + 1,
          elId.length
        );

        // No active filters -> display all
        if (activeParams.length === 0) {
          el.style.display = "block";
        } else {
          // Active filters -> display only filtered items
          if (activeParams.includes(elParamId)) {
            el.style.display = "block";
          } else {
            el.style.display = "none";
          }
        }
      }
    }
  };

  const init = () => {
    if (triggers?.length > 0) {
      // Initiate live filter
      LiveFilter(filterKey, triggers, clearBtn, onUpdate).init();
    }
  };

  return {
    init,
  };
};

const initiateAlphabetFilter = () => {
  const alphabetFilter = document.querySelector(
    ".alphabet-filter"
  ) as HTMLFieldSetElement;
  if (alphabetFilter) {
    AlphabetFilterModule(alphabetFilter).init();
  }
};

export { AlphabetFilterModule, initiateAlphabetFilter };
