import { getDeviceInfo } from "@/scripts";

const DiseaseItemModule = (diseaseItem: HTMLDivElement) => {
  const open = (target: HTMLButtonElement) => {
    const content = document.getElementById(
      target.getAttribute("aria-controls")!
    ) as HTMLDivElement;

    target.setAttribute("aria-expanded", "true");
    content.classList.remove("h-hidden");
    content.setAttribute("aria-hidden", "false")
    
    const deviceInfo = getDeviceInfo();    
    if(deviceInfo.isMobile)
    {
      content.scrollIntoView({ behavior: "smooth" });      
    }
  };

  const toggle = (e: Event) => {
    const target = e.currentTarget as HTMLButtonElement;

    const laboratoryList = diseaseItem.querySelectorAll(
      ".laboratory-list"
    ) as NodeListOf<HTMLDivElement>;
    
    laboratoryList.forEach(labList => {    
      labList.classList.add("h-hidden");
      labList.setAttribute("aria-hidden", "true")
    })
    
    const laboratoryListHelpText = diseaseItem.querySelector(
      ".laboratory-list-help-text"
    ) as HTMLParagraphElement;
    
    laboratoryListHelpText.classList.add('h-hidden')
    setOtherRadiosUnselected()
    open(target)
  }

  const setOtherRadiosUnselected = () => {    
    const triggers = diseaseItem.querySelectorAll(
      ".disease-item-radio"
    ) as NodeListOf<HTMLInputElement>;

    triggers.forEach((trigger) => trigger?.setAttribute("aria-expanded", "false"));  
  }

  const init = () => {
    const triggers = diseaseItem.querySelectorAll(
      ".disease-item-radio"
    ) as NodeListOf<HTMLInputElement>;

    triggers.forEach((trigger) => trigger?.addEventListener("click", toggle));
  };

  return {
    init,
  };
 };

const initiateDiseaseItem = () => {
  document
    .querySelectorAll(".disease-item")
    ?.forEach((diseaseItem) =>
    DiseaseItemModule(diseaseItem as HTMLDivElement).init()
    );
};

export { DiseaseItemModule, initiateDiseaseItem };
