/*
  Script module for initiating and triggering ReadSpeaker
*/

import {
  getCookieConsentByCategory,
  showCookieBannerDetails,
  highlightPreferencePanel,
} from "./_cookieBot";

const readSpeakerConf = {
  general: {
    usePost: true,
  },
  ui: {
    displayDownload: false,
    tools: {
      voicesettings: false,
      readhover: false,
      enlarge: false,
      simpleview: false,
      pagemask: false,
      translation: false,
      lookup: false,
      help: false,
    },
  },
};

const ReadSpeaker = () => {
  const readSpeakerBtns = document.querySelectorAll(
    ".read-speaker"
  ) as NodeListOf<Element>;

  const getInitializedState = () =>
    typeof window.ReadSpeaker === "object" &&
    typeof window.readpage === "function";

  const getScriptSrc = () => {
    let scriptSrc =
      "//cdn1.readspeaker.com/script/11438/webReader/webReader.js?pids=wr&jit=1";

    const hasDocReaderClass = Array.from(readSpeakerBtns).some(
      ({ classList }) => classList.contains("rs_docreader")
    );

    return hasDocReaderClass
      ? (scriptSrc += "&dload=DocReader.AutoAdd")
      : scriptSrc;
  };

  const checkIfScriptIsLoadedInHead = () => {
    const scriptSrc = getScriptSrc();
    const scripts = document.getElementsByTagName("script");
    let scriptLoaded = false;

    Array.from(scripts).map((script) => {
      if (script.src === `https:${scriptSrc}`) {
        scriptLoaded = true;
      }
    });

    return scriptLoaded;
  };

  const loadReadSpeakerScript = (scriptSrc: string) => {
    return new Promise((resolve, reject) => {
      const readSpeakerScriptLoaded = checkIfScriptIsLoadedInHead();

      if (readSpeakerScriptLoaded) {
        resolve("ReadSpeaker script loaded");
      } else {
        const script = document.createElement("script");
        script.type = "text/javascript";
        script.src = scriptSrc;
        document.head.appendChild(script);

        script.addEventListener("load", () => {
          if (
            typeof window.ReadSpeaker === "object" &&
            typeof window.readpage === "function"
          ) {
            resolve("ReadSpeaker script loaded");
          } else {
            reject(new Error("ReadSpeaker script did not load properly"));
          }
        });
      }
    });
  };

  const triggerReadPage = (btn: Element) => {
    const href = btn.getAttribute("href") ?? "/";

    const userHasConsentedToPreferencesCookies =
      getCookieConsentByCategory("preferences");

    if (!userHasConsentedToPreferencesCookies) {
      showCookieBannerDetails();
      highlightPreferencePanel();
    } else {
      if (typeof window.readpage === "function") {
        if (btn.id === "") {
          window.readpage(href, "readspeaker");
        } else {
          window.readpage(href, "readspeaker__" + btn.id);
        }
      }
    }
  };

  const init = async () => {
    const isInitialized = getInitializedState();
    if (!isInitialized) {
      const scriptSrc = getScriptSrc();

      // Add ReadSpeaker settings to window
      window.rsConf = {
        params: scriptSrc,
        ...readSpeakerConf,
      };
      window.rsDocReaderConf = {
        lang: "sv_se",
        img_alt: "Öppna detta dokument med ReadSpeaker docReader",
      };

      try {
        let userHasConsentedToPreferencesCookies =
          getCookieConsentByCategory("preferences");

        if (!userHasConsentedToPreferencesCookies) {
          // User hasn't accepted preferences cookies:
          // Add event listener for when consent is given and check if it includes preference cookies
          // If yes we add script and initialize
          window.addEventListener("CookiebotOnAccept", async () => {
            userHasConsentedToPreferencesCookies =
              getCookieConsentByCategory("preferences");

            if (userHasConsentedToPreferencesCookies) {
              await loadReadSpeakerScript(scriptSrc);
              window?.ReadSpeaker?.init();
            }
          });
        } else {
          await loadReadSpeakerScript(scriptSrc);
          window?.ReadSpeaker?.init();
        }

        readSpeakerBtns.forEach((btn) => {
          btn.addEventListener("click", (e: Event) => {
            e.preventDefault();
            triggerReadPage(btn);
          });
        });
      } catch (error) {
        console.error(error);
      }
    }
  };

  return {
    init,
  };
};

export { ReadSpeaker };
