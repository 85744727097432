import debounce from "lodash.debounce";

const ScrollToTopModule = () => {
  const scrollTopEl: HTMLElement | null =
    document.querySelector(".scroll-to-top");
  const mediaQuery = window.matchMedia("screen and (max-width: 968px)");

  const scrollHandler = debounce(() => {
    if (scrollTopEl && mediaQuery.matches) {
      const scrollY = document.documentElement.scrollTop;
      // Set class to reveal ScrollToTop component
      if (scrollY > 100) {
        scrollTopEl.classList.add("scroll-to-top--visible");
      } else {
        scrollTopEl.classList.remove("scroll-to-top--visible");
      }
    }
  }, 10);

  const scrollTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    scrollTopEl?.blur();
  };

  const init = () => {
    if (scrollTopEl) {
      window.addEventListener("scroll", scrollHandler);
      scrollTopEl.addEventListener("click", scrollTop);
    }
  };

  return {
    init,
  };
};

export { ScrollToTopModule };
