/*
    Simple JS function for revealing hidden markup.
    Looks for a button with the class "show-more-trigger" and a container 
    holding the hidden items, specific in the "data-show-more-container-class" data attribute. 

    On trigger all "h-hidden" classes inside the container will be removed.
*/

const ShowMore = (trigger: HTMLButtonElement) => {
  const containerClass = trigger.getAttribute(
    "data-show-more-container-class"
  ) as string;
  const container = document.querySelector(`.${containerClass}`);

  const removeHiddenClass = () => {
    container!.querySelectorAll(".h-hidden").forEach((item) => {
      item.classList.remove("h-hidden");
    });

    trigger.classList.add("h-hidden");
  };

  const init = () => {
    if (trigger && container) {
      trigger.addEventListener("click", removeHiddenClass);
    }
  };

  return {
    init,
  };
};

export { ShowMore };
