/**
 Script module used for fetching HTML content based on filter choice, inside tabs on statistics pages
 */

import axios from "axios";

import {
  setURLSearchParams,
  getQueryStringParamsAsKeyValuePair,
} from "@/scripts";
import { HorizontalScrollerModule } from "@/components/HorizontalScroller/HorizontalScroller.Module";

interface StatisticsContentResult {
  result: string;
  success: boolean;
}

const StatisticsContentSwitcher = (el: HTMLDivElement) => {
  // Collection of Select elements that trigger fetch of content
  const selects = Array.from(el.getElementsByTagName("select"));
  // Query keys used in request for content
  const queryKeys = el
    .getAttribute("data-query-key")!
    .split(",")
    .map((key) => key.trim());

  /*
   * @param value: string (Value from select option that triggered change event)
   */
  const getContent = async (): Promise<StatisticsContentResult> => {
    const endpoint = el.getAttribute("data-endpoint")!;
    const params = new URLSearchParams(window.location.search);
    const activeQsParams = getQueryStringParamsAsKeyValuePair(queryKeys);
    const activeTab = params.get("tab");

    const r = await axios.get(endpoint, {
      params: {
        ...activeQsParams,
        ...(activeTab && { tab: activeTab }),
      },
    });
    const { data } = r;

    return data;
  };

  /*
   * @param contentHTML: string (fetched content HTML as string)
   */
  const updateContentHTML = (contentHTML: string) => {
    const contentContainerId = el.getAttribute(
      "data-content-container-id"
    ) as string;
    const contentContainer = document.getElementById(
      contentContainerId
    ) as HTMLDivElement;

    contentContainer.innerHTML = contentHTML;

    // If updated content uses horizontal scroller, we reinitialize the JS for it
    const horizontalScrollerEl = contentContainer.querySelector(
      ".horizontal-scroller"
    );
    if (horizontalScrollerEl) {
      HorizontalScrollerModule(horizontalScrollerEl as HTMLElement).init();
    }
  };

  const onChange = async (e: Event) => {
    const currentTarget = e.currentTarget as HTMLSelectElement;
    // Selected query value
    const { value } = currentTarget;
    const queryKey = currentTarget.getAttribute("id")!;
    // Add/update query params in URL
    setURLSearchParams(queryKey, value);

    const { result, success } = await getContent();

    if (success) {
      updateContentHTML(result);
    }
  };

  const init = () => {
    if (selects?.length > 0) {
      selects.forEach((select) => select.addEventListener("change", onChange));
    }
  };

  return {
    init,
  };
};

export { StatisticsContentSwitcher };
